var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    {
      attrs: {
        app: "",
        clipped: "",
        color: _vm.$config("app.layoutColors.footerBackground"),
        "min-height": "40",
        dark: ""
      }
    },
    [
      _c("h4", { staticClass: "font-weight-regular" }, [
        _vm._v(
          " © " +
            _vm._s(new Date().getFullYear()) +
            " — " +
            _vm._s(_vm.$config("app.companyName")) +
            " "
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }